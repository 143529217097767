import React from 'react'
import './Footer.css'
import { Bounce } from 'react-reveal'
import { FaLinkedin } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { SiUpwork } from "react-icons/si";






const Footer = () => {
  return (
    <footer id="footer">
    <div className="container cont">
    <h3>Usama Iqbal</h3>
      <p>
        Creative professionals who blend design and development skills to
        craft visually captivating websites with intuitive interfaces for
        exceptional user experiences.
      </p>
      
      <div className="social-links">
      <Bounce cascade>
        <a
          href="https://www.upwork.com/freelancers/~0156a7eac8335f97e9"
          target="_blank"
          className="facebook"
          rel="noopener noreferrer"
        >
          <SiUpwork className='icon-1'/>
        </a>
        <a
          href="https://www.linkedin.com/in/usama-iqbal07/"
          target="_blank"
          className="linkedin"
          rel="noopener noreferrer"
        >
          <FaLinkedin className='icon-1'/>
        </a>
        <a
          href=" https://github.com/usamaarian007"
          target="_blank"
          className="upwork"
          rel="noopener noreferrer"
        >
          <FaGithub className='icon-1'/>
        </a>
        {/* <a
          href="mailto:ecomet.technologies@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
          className="upwork"
        >
          <SiCalendly className='icon-1'/>          
        </a> */}
        </Bounce>
      </div>
     
      <div className="copyright">
        <p>Copyright © 2024 Usama Iqbal. All Rights Reserved.</p>
      </div>
      <div className="designed">
        <p>
          Designed By{" "}
          <a href="https://teamecomet.com/" target="_blank" rel="noopener noreferrer">
            eComet Technologies
          </a>
        </p>
      </div>
    </div>
  </footer>
  )
}

export default Footer
